import React from "react";
import { NavLink } from "react-router-dom";
import remarkGfm from "remark-gfm";
import Markdown from "react-markdown";

function StandartArticle(props) {
  return (
    <div className="mt-2">
      <Markdown
        remarkPlugins={[remarkGfm]}
        components={{
          p(props) {
            const { node, ...rest } = props;
            return <p style={{ marginBottom: "25px" }} {...rest} />;
          },
        }}
      >
        {props.description}
      </Markdown>
    </div>
  );
}
export default StandartArticle;
