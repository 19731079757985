import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from "./components/App/App"
import { BrowserRouter } from 'react-router-dom';
// import { hydrate, render } from "react-dom";
import { hydrateRoot } from 'react-dom/client';


// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter > 
//         <App />
//     </BrowserRouter> 
//   </React.StrictMode>
// );

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement,<React.StrictMode>
    <BrowserRouter >
        <App />
    </BrowserRouter> 
  </React.StrictMode> )
  // hydrate(<React.StrictMode>
  //   <BrowserRouter > 
  //       <App />
  //   </BrowserRouter> 
  // </React.StrictMode>, rootElement);
} else {
  const root = ReactDOM.createRoot(rootElement)
  root.render(<React.StrictMode>
    <BrowserRouter > 
        <App />
    </BrowserRouter> 
  </React.StrictMode>);
}
