import React from "react";
import TechnicalSolutionsContent from "../../../components/technicalSolutionsContent/TechnicalSolutionsContent";
import { AppRoute } from "../../../constants";
import img1 from "../../../images/technicalSolutions/Pf1.png";
import img2 from "../../../images/technicalSolutions/Pf2.png";
import img3 from "../../../images/technicalSolutions/Pf3.png";
import img4 from "../../../images/technicalSolutions/Pf4.png";
import GOST from "../../../components/gost/GOST";
import Layout from "../../../components/Layout/Layout";
import StandartArticle from "../../../components/Articles/StandartArticle";
import { InlineMath, BlockMath } from "react-katex";
import TechnicalSolutionSlider from "../../../components/technicalSolutionSlider/TechnicalSolutionSlider";
import style from "./Se.module.css";
import "katex/dist/katex.min.css";

const images = [
  {
    title: "Табличная форма с элементами схемы замещения, входящими в схему",
    img: img1,
    type: "image",
  },
  {
    title: "Табличная форма силового оборудования, входящего в схему",
    img: img2,
    type: "image",
  },
  {
    title: "Графическая визуализация схемы сети",
    img: img3,
    type: "image",
  },
  {
    title: "Отображение результатов расчета на графике",
    img: img4,
    type: "image",
  },
];

const functions = [
  {
    id: 1,
    title:
      "Расчет установившихся режимов (величины токов в ветвях, активной и реактивной мощности в ветвях, напряжения в узлах схемы электроснабжения предприятия)",
  },
  {
    id: 2,
    title:
      "Расчет электрических потерь мощности (активной и реактивной) и напряжения в элементах сети электроснабжения",
  },
  {
    id: 3,
    title:
      "Осуществление контроля за уровнями напряжения, токов и нагрузкой энерго, и техоборудования, сигнализаций при превышении допустимых отклонений",
  },
  {
    id: 4,
    title:
      "Оперативный контроль технологического режима объектов электрической сети",
  },
  {
    id: 5,
    title:
      "Анализ работы распределительной сети с возможностью ретроспективного анализа сетевых неисправностей",
  },
  {
    id: 6,
    title: "Мониторинг и сигнализацию текущего состояния устройств РЗА",
  },
  {
    id: 7,
    title:
      "Расчет отклонений от расчетных данных режимов на основе электрических величин (величины токов в ветвях, активной и реактивной мощности в ветвях, напряжения в узлах схемы электроснабжения предприятия)",
  },
  { id: 8, title: "Оценка ошибки измерений" },
  {
    id: 9,
    title:
      "Предупреждение по результатам проведенного анализа возможных последствий неблагоприятных ситуаций с целью преждевременного устранения возможных технологических нарушений",
  },
];

const extensions = [
  {
    link: AppRoute.VectorPlugin,
    title: "vector-plugin",
  },
];

function Se() {
  const formula1 = `x = [V_{1}, θ_{1}, V_{2}, θ_{2}, ..., V_{N}, θ_{N}]^T`;
  const formula2 = `J(x) = \\sum_{i=1}^m\\ w_{i}(z_{i} - h_{i}(x))^2`;

  return (
    <Layout>
      <div className="main">
        <GOST />
        <h1 className="mb-4 text-center">{`Техническое решение по оцениванию состояния установившегося режима сети`}</h1>
        <StandartArticle
          description={`**Оценивание состояния** (state estimation) в задачах анализа режима электрической сети — это процесс определения наиболее вероятного состояния энергосистемы на основе измерений, полученных от различных устройств, таких как SCADA (Supervisory Control and Data Acquisition), PMU (Phasor Measurement Units) и других источников данных. Это важный этап в управлении и мониторинге энергосистем, так как позволяет получить точную информацию о текущем режиме работы сети, что необходимо для принятия решений по управлению, оптимизации и обеспечению надежности работы электрической сети


### Методы оценивания состояния

В данном техническом решении используется наиболее распространенный метод оценивания состояния, основанный на минимизации суммы квадратов невязок - *Метод взвешенных наименьших квадратов (Weighted Least Squares, WLS)*.


### Основные задачи оценивания состояния
В рамках оценивания состояния сети решается комплекс задач, основанных на анализе режима электрической сети. Рассмотрим, как решаются эти задачи в данном техническом решении.

#### 1. Определение состояния сети

Состояние сети описывается вектором переменных, таких как модули и фазы напряжений в узлах (фазовые углы и амплитуды). Например, для сети с N узлами, состояние может быть представлено как:
`}
        />
        <div className="d-flex jusify-content-center mb-3">
          <p>
            <InlineMath math={formula1} />
          </p>
        </div>
        <div className="d-flex flex-wrap" style={{ gap: "5px" }}>
          <InlineMath
            math={`где\\ V_{i} - модуль\\ напряжения, а \\ θ_{i} - фазовый\\ угол\\ в\\ узле\\ i`}
          />
        </div>
        <StandartArticle
          description={`
#### 2. Обработка измерений 
Решение задачи оценивания состояния выполняется на основе измерений, полученных от различных источников. Измерения могут включать в себя активную и реактивную мощности, токи, напряжения, фазовые углы и другие параметры. В силу различных факторов измерения могут быть зашумленными или содержать ошибки, поэтому в данном техническом решении выполняется фильтрация зашумленных данных и выявляются ошибки измерений.
#### 3. Решение задачи оптимизации 
Решение задачи оптимизации сети в данном техническом решении выполняется как минимизация невязки между измеренными значениями и расчетными значениями, полученными на основе модели сети. Для решения этой задачи используется метод взвешенных наименьших квадратов (WLS), где минимизируется целевая функция:

`}
        />
        <div className="d-flex jusify-content-center mb-3">
          <InlineMath math={formula2} />
        </div>
        <div
          className={`d-flex flex-wrap ${style.formula} mb-2`}
          style={{ gap: "5px" }}
        >
          <InlineMath
            math={`где\\ z_{i} - измеренное\\ значение,\\\\h_{i}(x) - функция, связывающая\\ состояние\\ сети\\ с\\ измерением,\\\\w_{i} - вес\\ измерения, отражающий\\ его\\ точность`}
          />
        </div>
        <StandartArticle
          description={`
#### 4. Обнаружение и устранение ошибок
В процессе оценивания состояния в данном техническом решении выявляются грубые ошибки (bad data), которые могут быть вызваны неисправностями измерительных устройств или ошибками передачи данных. Для решения этой задачи используется анализ невязок, анализ положения коммутационных аппаратов и статистические методы.

### Основные этапы оценивания состояния: 
1. Сбор данных - получение измерений от различных устройств в энергосистеме.

2. Предварительная обработка данных фильтрация и проверка данных на наличие ошибок.

3. Решение задачи оптимизации - использование численных методов для нахождения вектора состояния, минимизирующего целевую функцию.

4. Анализ результатов - проверка качества оценивания, обнаружение и устранение ошибок.

5. Использование результатов - применение полученных данных для управления режимами сети, анализа устойчивости, оптимизации и других задач.

`}
        />
      </div>
    </Layout>
  );
}

export default Se;
